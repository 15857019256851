<template>
  <div class=" RefundTreatBillCreate" v-loading="loading">
    <el-row class="border_bottom">
      <el-col :span="9" class="pad_15 ">
        <el-autocomplete popper-class="customer-autocomplete" prefix-icon="el-icon-user-solid" v-model="customerName" style="width:100%" size="small" placeholder="请输入客户姓名、手机号、编号查找" :fetch-suggestions="saleCustomerData" @select="handleCustomerSelect" :disabled="customerID!=null" :trigger-on-focus="false" :hide-loading="true" :highlight-first-item="true" :select-when-unmatched="true">
          <template slot="append">
            <el-button icon="el-icon-delete" @click="removeCustomer"></el-button>
          </template>
          <template slot-scope="{ item }">
            <div class="name">
              {{ item.Name}}
              <el-tag size="mini" v-if="item.CustomerLevelName">{{ item.CustomerLevelName }}</el-tag>
            </div>
            <div class="info">手机号：{{ item.PhoneNumber }}</div>
            <span class="info" v-if="item.Code">客户编号：{{ item.Code }}</span>
          </template>
        </el-autocomplete>
      </el-col>
      <el-col :span="15" class="pad_15 border_left">
        <el-col :span="12" style="white-space: nowrap">
          <el-form :inline="true" size="small">
            <el-form-item style="margin-bottom:0px;height:32px" label="退消耗时间">
              <span slot="label">
                退消耗时间
                <el-popover placement="bottom-start" trigger="hover">
                  <p>1.退消耗时间多用于补开历史退消耗订单。</p>
                  <p>2.使用退消耗时间后该笔订单的相关营收将计入补录的时间。</p>
                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                </el-popover>
              </span>
              <el-switch v-model="IsSupplement" @change="isSupplementChange"></el-switch>
            </el-form-item>
            <el-form-item style="margin-bottom:0px;height:32px" v-show="IsSupplement">
              <el-date-picker v-model="BillDate" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" default-time="9:30:00"></el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-col>
    </el-row>

    <el-row class="refundTreatBill_create">
      <el-col :span="9" class="project_left">
        <div class="pad_15 border_bottom">
          <el-input prefix-icon="el-icon-search" placeholder="请输入商品名称、别名关键字、订单编号，按回车搜索" v-model="goodsName" clearable size="small" @keyup.enter.native="searchGoodsClick" @clear="clearClick"></el-input>
        </div>
        <el-scrollbar class="el-scrollbar_height" v-loading="loading">
          <div style="height:100%">
            <el-row v-for="(goods,index) in GoodsList" :key="index+'goods'">
              <el-row class=" pad_10 backs_color">
                <el-col :span="12" class="font_13">订单时间：{{goods.BillDate}}</el-col>
                <el-col :span="12" class="font_12 text_right color_666">订单编号：{{goods.ID}}</el-col>
              </el-row>
              <!-- 项目 -->
              <el-card class="mar_5_10 cursor_pointer" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in goods.Project" :key="index+'project'" @click.native="clickInsertList(item,0,goods)">
                <div slot="header">
                  <span>{{item.ProjectName}}</span>
                  <span v-if="item.Alias">({{item.Alias}})</span>
                  <el-tag size="mini" class="marlt_5">项目</el-tag>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                </div>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">项目单价：</span>
                      <span>¥ {{item.Price | NumFormat}}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">消耗数量：</span>
                      <span>{{item.Quantity}}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">消耗金额：</span>
                      <span>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
              <!-- 产品 -->
              <el-card class="mar_5_10 cursor_pointer" :body-style="{ padding: '0px' }" shadow="hover" v-for="(item,index) in goods.Product" :key="index+'produ'" @click.native="clickInsertList(item,1,goods)">
                <div slot="header">
                  <span>{{item.ProjectName}}</span>
                  <span v-if="item.Alias">({{item.Alias}})</span>
                  <el-tag size="mini" class="marlt_5">产品</el-tag>
                  <el-tag v-if="item.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                </div>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">产品单价：</span>
                      <span> ￥{{item.Price | NumFormat}}</span>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">消耗数量：</span>
                      <span>{{item.Quantity}}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">消耗金额：</span>
                      <span>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
              <!-- 通用次卡 -->
              <el-row class="mar_10 border radius5 overflow_hidden" v-for="(item,index) in goods.GeneralCard" :key="index +'gen'">
                <div class="backf5f7fa pad_10">
                  <span>{{item.GeneralCardName}}</span>
                  <span v-if="item.Alias">({{item.Alias}})</span>
                  <el-tag size="mini" class="marlt_5">通用次卡</el-tag>
                </div>
                <el-col>
                  <el-card class="cursor_pointer cardStyle_none martp_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in item.Project" :key="index +'genral'" @click.native="clickInsertCardList(pro,item,2,goods.BillDate,goods.ID,goods)">
                    <div slot="header">
                      <span>{{pro.ProjectName}}</span>
                      <span v-if="pro.Alias" class="font_13">({{pro.Alias}})</span>
                      <el-tag size="mini" effect="plain" v-if="pro.IsLargess" type="danger" class="marlt_5">赠</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">项目单价：</span>
                          <span> ￥{{pro.Price | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">耗用次数：</span>
                          <span> {{pro.CardTreatTimes}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class=" border_right pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                      <el-col :span="8" class="pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
              <!-- 时效卡 -->
              <el-row class="mar_10 border radius5 overflow_hidden" v-for="(item,index) in goods.TimeCard" :key="index +'TimeCard'">
                <div class="backf5f7fa pad_10">
                  <span>{{item.TimeCardName}}</span>
                  <span v-if="item.Alias">({{item.Alias}})</span>
                  <el-tag size="mini" class="marlt_5">时效卡</el-tag>
                </div>
                <el-col class="padbm_5">
                  <el-card class="cursor_pointer cardStyle_none martp_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in item.Project" :key="index +'genral'" @click.native="clickInsertCardList(pro,item,3,goods.BillDate,goods.ID,goods)">
                    <div slot="header">
                      <span>{{pro.ProjectName}}</span>
                      <span v-if="pro.Alias" class="font_13">({{pro.Alias}})</span>
                      <el-tag size="mini" effect="plain" v-if="pro.IsLargess" type="danger" class="marlt_5">赠</el-tag>
                    </div>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">项目单价：</span>
                          <span> ￥{{pro.Price | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row class="border_top">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">本次减重：</span>
                          <span> {{pro.LostWeight}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="goods-item">
                          
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                         
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
              <!-- 储值卡 -->
              <el-row class="mar_10 border radius5 overflow_hidden" v-for="(item,index) in goods.SavingCard" :key="index +'Save'">
                <div class="backf5f7fa pad_10">
                  <span>{{item.SavingCardName}}</span>
                  <span v-if="item.Alias">({{item.Alias}})</span>
                  <el-tag size="mini" class="marlt_5">储值卡</el-tag>
                </div>
                <el-col>
                  <el-card class="cursor_pointer cardStyle_none martp_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in item.Project" :key="index +'save'" @click.native="clickInsertCardList(pro,item,4,goods.BillDate,goods.ID,goods)">
                    <div slot="header">
                      <span>{{pro.ProjectName}}</span>
                      <span v-if="pro.Alias" class="font_13">({{pro.Alias}})</span>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">项目单价：</span>
                          <span> ￥{{pro.Price | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">优惠金额：</span>
                          <span> ￥{{pro.CardPreferentialAmount | NumFormat}} </span>
                        </div>
                      </el-col>
                      <el-col :span="8" class=" border_right pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                      <el-col :span="8" class="pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
              <!-- 套餐卡 -->
              <el-row class="marbm_10 marlt_10 marrt_10 radius5 border overflow_hidden" :class="index==0?'martp_10':''" v-for="(item,index) in goods.PackageCard" :key="index + 'pack'">
                <el-col :span="24" class="backf5f7fa pad_10">
                  <span>{{item.PackageCardName}}</span>
                  <span v-if="item.Alias">({{item.Alias}})</span>
                  <el-tag size="mini" class="marlt_5">套餐卡</el-tag>
                </el-col>

                <!-- 项目 -->
                <el-col>
                  <el-card class="cursor_pointer cardStyle_none martp_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in item.Project" :key="index +'packppro'" @click.native="clickInsertPackageCard(0,goods,item,pro)">
                    <div slot="header">
                      <span>{{pro.ProjectName}}</span>
                      <span v-if="pro.Alias">({{pro.Alias}})</span>
                      <el-tag size="mini" class="marlt_5">项目</el-tag>
                      <el-tag v-if="pro.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </div>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗单价：</span>
                          <span> ￥{{pro.Price | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount |NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
                <!-- 产品 -->
                <el-col>
                  <el-card class="cursor_pointer cardStyle_none martp_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in item.Product" :key="index +'packpdu'" @click.native="clickInsertPackageCard(1,goods,item,pro)">
                    <div slot="header">
                      <span> {{pro.ProductName}}</span>
                      <span v-if="pro.Alias">({{pro.Alias}})</span>
                      <el-tag size="mini" class="marlt_5">产品</el-tag>
                      <el-tag v-if="pro.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                    </div>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗单价：</span>
                          <span> ￥{{pro.Amount | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
                <!-- 通用从卡 -->
                <el-col v-for="(Gen,index) in item.GeneralCard" :key="index +'GeneralCard'">
                  <div class="backf5f7fa pad_10 border_bottom martp_5">
                    <span>{{Gen.GeneralCardName}}</span>
                    <span v-if="Gen.Alias">({{Gen.Alias}})</span>
                    <el-tag size="mini" class="marlt_5">通用次卡</el-tag>
                  </div>
                  <el-card class="cursor_pointer cardStyle_none marbm_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in Gen.Project" :key="index +'genral'" @click.native="clickInsertPackageCard(2,goods,item,Gen,pro)">
                    <div slot="header">
                      <span>{{pro.ProjectName}}</span>
                      <span v-if="pro.Alias" class="font_13">({{pro.Alias}})</span>
                      <el-tag size="mini" effect="plain" v-if="pro.IsLargess" type="danger" class="marlt_5">赠</el-tag>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗单价：</span>
                          <span> ￥{{pro.Amount | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">耗用次数：</span>
                          <span>{{pro.CardTreatTimes}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class=" border_right pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                      <el-col :span="8" class="pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
                <!-- 时效卡 -->
                <el-col v-for="(time,index) in item.TimeCard" :key="index +'time'">
                  <div class="backf5f7fa border_bottom pad_10 martp_5">
                    <span>{{time.TimeCardName}}</span>
                    <span v-if="time.Alias">({{time.Alias}})</span>
                    <el-tag size="mini" class="marlt_5">时效卡</el-tag>
                  </div>
                  <el-card class="cursor_pointer cardStyle_none marbm_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in time.Project" :key="index +'genral'" @click.native="clickInsertPackageCard(3,goods,item,time,pro)">
                    <div slot="header">
                      <span>{{pro.ProjectName}}</span>
                      <span v-if="pro.Alias" class="font_13">({{pro.Alias}})</span>
                      <el-tag size="mini" effect="plain" v-if="pro.IsLargess" type="danger" class="marlt_5">赠</el-tag>
                    </div>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗单价：</span>
                          <span>￥{{pro.Amount | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8" class="border_left">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
                <!-- 储值卡 -->
                <el-col v-for="(save,index) in item.SavingCard" :key="index +'save'">
                  <div class="backf5f7fa pad_10 border_bottom martp_5">
                    <span>{{save.SavingCardName}}</span>
                    <span v-if="save.Alias">({{save.Alias}})</span>
                    <el-tag size="mini" class="marlt_5">储值卡</el-tag>
                  </div>
                  <el-card class="cursor_pointer cardStyle_none marbm_5" :body-style="{ padding: '0px' }" shadow="hover" v-for="(pro,index) in save.Project" :key="index +'genral'" @click.native="clickInsertPackageCard(4,goods,item,save,pro)">
                    <div slot="header">
                      <span>{{pro.ProjectName}}</span>
                      <span v-if="pro.Alias" class="font_13">({{pro.Alias}})</span>
                    </div>
                    <el-row class="border_bottom">
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗单价：</span>
                          <span> ￥{{pro.Price | NumFormat}}</span>
                        </div>
                      </el-col>

                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">消耗数量：</span>
                          <span>{{pro.Quantity}}</span>
                        </div>
                      </el-col>
                      <el-col :span="8">
                        <div class="goods-item">
                          <span class="goods-lable">消耗金额：</span>
                          <span>￥{{pro.TotalAmount | NumFormat}}</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="border_right">
                        <div class="goods-item">
                          <span class="goods-lable">优惠金额：</span>
                          <span> ￥{{pro.CardPreferentialAmount | NumFormat}} </span>
                        </div>
                      </el-col>
                      <el-col :span="8" class=" border_right pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                      <el-col :span="8" class="pad_5_0">
                        <div style="min-height:26px;"></div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </el-row>
          </div>
        </el-scrollbar>
      </el-col>
      <el-col :span="15" class="project_right">
        <el-container style="height: 100%" direction="vertaical">
          <el-main>
            <el-scrollbar class="el-scrollbar_height color_333 ">
              <el-row class="martp_10 marlt_10 marrt_10  border radius5 overflow_hidden" v-for="(item,ListIndex) in TreatBillList" :key="ListIndex+'treat'">
                <div class="backs_color pad_10 border_bottom">
                  <el-row>
                    <el-col :span="12" class="font_13">订单时间：{{item.BillDate}}</el-col>
                    <el-col :span="12" class="text_right font_12 color_666">订单编号：{{item.ID}}</el-col>
                  </el-row>
                </div>
                <!-- 项目 -->
                <el-row class="pad_10  font_13 backs_color" v-if="item.project.length>0">
                  <el-col :span="10">项目</el-col>
                  <el-col :span="8">数量</el-col>
                  <el-col :span="5">退款金额</el-col>
                  <el-col :span="1">操作</el-col>
                </el-row>
                <el-row v-for="(pro,indexpro) in item.project" :key="indexpro +'projrct1'">
                  <el-col :span="24">
                    <el-row class="pad_10 border_bottom border_top">
                      <el-col :span="10" class="font_13">
                        <div>
                          {{pro.ProjectName}}
                          <span v-if="pro.Alias">({{pro.Alias}})</span>
                          <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                        </div>
                        <div class="color_red martp_5">￥ {{pro.Price | NumFormat}}</div>
                      </el-col>
                      <el-col :span="8">
                        <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,1)"></el-input-number>
                      </el-col>
                      <el-col :span="5">
                        <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageClick(1,item.project,'',pro,indexpro,item,ListIndex)"></el-button>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0">
                    <el-row class="cursor_pointer" @click.native="employeeHandleClick(1,item.project,pro,indexpro)" v-for="(handler,index) in pro.handleTypeList" :key="index +'hand'">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                          <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                          <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                            <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <!-- 产品 -->
                <el-row class="pad_10 font_13 backs_color" v-if="item.product.length > 0">
                  <el-col :span="10">产品</el-col>
                  <el-col :span="8">数量</el-col>
                  <el-col :span="5">退款金额</el-col>
                  <el-col :span="1">操作</el-col>
                </el-row>
                <el-row v-for="(pro,indexduc) in item.product" :key="indexduc +'proje'">
                  <el-col :span="24">
                    <el-row class="pad_10 border_bottom border_top">
                      <el-col :span="10" class="font_13">
                        <div>
                          {{pro.ProjectName}}
                          <span v-if="pro.Alias">({{pro.Alias}})</span>
                          <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                        </div>
                        <div class="color_red martp_5">￥ {{pro.Price | NumFormat}}</div>
                      </el-col>
                      <el-col :span="8">
                        <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,1,'')"></el-input-number>
                      </el-col>
                      <el-col :span="5">
                        <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageClick(2,item.product,'',pro,indexduc,item,ListIndex)"></el-button>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0">
                    <el-row class="cursor_pointer" @click.native="employeeHandleClick(6,item.product,pro,indexduc)" v-for="(handler,index) in pro.handleTypeList" :key="index +'hand'">
                      <el-col :span="4">
                        <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                          <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                          <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                            <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                              <template slot="append">%</template>
                            </el-input>
                            <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <!--  通用次卡  -->
                <el-row class="pad_10 border_bottom font_13 backs_color" v-if="item.generalCard.length>0">
                  <el-col :span="10">通用次卡</el-col>
                  <el-col :span="8">数量</el-col>
                  <el-col :span="5">退款金额</el-col>
                  <el-col :span="1">操作</el-col>
                </el-row>
                <el-row class="martp_5" v-for="(gen,indexbb) in item.generalCard" :key="indexbb +'genral'">
                  <div class="backf5f7fa  pad_0_10  line_height_40">
                    <el-row>
                      <el-col :span="12" class="font_13">
                        {{gen.ProjectName}}
                        <span v-if="gen.Alias">({{gen.Alias}})</span>
                      </el-col>
                      <el-col :span="12" class="text_right font_12 color_666">将退回：{{gen.TreatNumber}}次</el-col>
                    </el-row>
                  </div>
                  <el-row v-for="(pro,indexgen) in gen.project" :key="indexgen +'a'">
                    <el-col :span="24">
                      <el-row class="pad_10 border_bottom border_top">
                        <el-col :span="10" class="font_13">
                          <div>
                            {{pro.ProjectName}}
                            <span v-if="pro.Alias">({{pro.Alias}})</span>
                            <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                          </div>
                          <div class="color_red martp_5">
                            ￥ {{pro.Price | NumFormat}}
                            <span class="font_12 color_666 marlt_5">耗卡次数{{pro.CardTreatTimes}}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,2,gen)"></el-input-number>
                        </el-col>
                        <el-col :span="5">
                          <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                        </el-col>
                        <el-col :span="1">
                          <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageClick(3,item.generalCard,gen.project,pro,indexgen,item,ListIndex,indexbb)"></el-button>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24" class="pad_10 padbm_0 ">
                      <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(4,gen.project,pro,indexgen)">
                        <el-col :span="4">
                          <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                            <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="20">
                          <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                            <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                              <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                <template slot="append">%</template>
                              </el-input>
                              <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
                <!--  时效卡  -->
                <el-row class="pad_10 border_bottom font_13 backs_color" v-if="item.timeCard.length>0">
                  <el-col :span="6">时效卡</el-col>
                  <el-col :span="6">数量</el-col>
                  <el-col :span="6">本次减重</el-col>
                  <el-col :span="5">退款金额</el-col>
                  <el-col :span="1">操作</el-col>
                </el-row>
                <el-row class="martp_5" v-for="(time,indexbb) in item.timeCard" :key="indexbb +'timecards'">
                  <div class="backf5f7fa pad_0_10  line_height_40">
                    <el-row>
                      <el-col :span="12" class="font_13">
                        {{time.ProjectName}}
                        <span v-if="time.Alias">({{time.Alias}})</span>
                      </el-col>
                      <el-col :span="12" class="text_right font_12 color_666"></el-col>
                    </el-row>
                  </div>
                  <el-row v-for="(pro,indextime) in time.project" :key="indextime +'a'">
                    <el-col :span="24">
                      <el-row class="pad_10 border_top border_bottom">
                        <el-col :span="6" class="font_13">
                          <div>
                            {{pro.ProjectName}}
                            <span v-if="pro.Alias">({{pro.Alias}})</span>
                            <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                          </div>
                          <div class="color_red martp_5">
                            ￥ {{pro.Price | NumFormat}}
                          </div>
                        </el-col>
                        <el-col :span="6">
                          <!-- <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,1,'')"></el-input-number> -->
                          <div class="font_13">{{pro.number}}</div>
                        </el-col>
                        <el-col :span="6">
                         <div class="font_13">{{pro.LostWeight}} 斤</div>
                        </el-col>
                        <el-col :span="5">
                          <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                        </el-col>
                        <el-col :span="1">
                          <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageClick(4,item.timeCard,time.project,pro,indextime,item,ListIndex,indexbb)"></el-button>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24" class="pad_10 padbm_0 ">
                      <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(3,time.project,pro,indextime)">
                        <el-col :span="4">
                          <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                            <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="20">
                          <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                            <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                              <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                <template slot="append">%</template>
                              </el-input>
                              <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
                <!--  储值卡  -->
                <el-row class="pad_10 border_bottom font_13 backs_color" v-if="item.savingCard.length>0">
                  <el-col :span="10">储值卡</el-col>
                  <el-col :span="8">数量</el-col>
                  <el-col :span="5">退款金额</el-col>
                  <el-col :span="1">操作</el-col>
                </el-row>
                <el-row class="martp_5" v-for="(save,indexbb) in item.savingCard" :key="indexbb +'sa'">
                  <div class="backf5f7fa pad_0_10  line_height_40">
                    <el-row>
                      <el-col :span="12" class="font_13">
                        {{save.ProjectName}}
                        <span v-if="save.Alias">({{save.Alias}})</span>
                      </el-col>
                      <el-col :span="12" class="text_right font_12 color_666"></el-col>
                    </el-row>
                  </div>
                  <el-row v-for="(pro,indexsave) in save.project" :key="indexsave +'a'">
                    <el-col :span="24">
                      <el-row class="pad_10 border_bottom border_top">
                        <el-col :span="10" class="font_13">
                          <div>
                            {{pro.ProjectName}}
                            <span v-if="pro.Alias">({{pro.Alias}})</span>
                            <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                          </div>
                          <div class="color_red martp_5">
                            ￥ {{pro.Price | NumFormat}}
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,3,'')"></el-input-number>
                        </el-col>
                        <el-col :span="5">
                          <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                        </el-col>
                        <el-col :span="1">
                          <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageClick(5,item.savingCard,save.project,pro,indexsave,item,ListIndex,indexbb)"></el-button>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24" class="pad_10 padbm_0 ">
                      <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(2,save.project,pro,indexsave)">
                        <el-col :span="4">
                          <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                            <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="20">
                          <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                            <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                              <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                <template slot="append">%</template>
                              </el-input>
                              <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-row>
                <!--  套餐卡  -->
                <el-row v-if="item.packageCard.length>0">
                  <el-row class="marbm_5" v-for="(pac,packageIndex) in item.packageCard" :key="packageIndex +'pac'">
                    <!-- 套餐卡名称  -->
                    <el-row class="pad_0_10 line_height_40 backs_color font_13">
                      <el-col :span="6">{{pac.PackageCardName}}
                        <span v-if="pac.Alias">({{pac.Alias}})</span>
                        <el-tag size="mini" class="marlt_5">套餐卡</el-tag>
                      </el-col>
                      <el-col :span="6">数量</el-col>
                      <el-col :span="6">已减体重</el-col>
                      <el-col :span="5">折扣/折后金额</el-col>
                      <el-col :span="1">操作</el-col>
                    </el-row>
                    <!-- 套餐卡项目 -->
                    <el-row v-for="(pro,projectIndex) in pac.Project" :key="projectIndex +'a'">
                      <el-col :span="24">
                        <el-row class="pad_10 border_bottom border_top">
                          <el-col :span="6" class="font_13">
                            {{pro.ProjectName}}
                            <span v-if="pro.Alias">({{pro.Alias}})</span>
                            <el-tag size="mini" class="marlt_5">项目</el-tag>
                            <el-tag v-if="pro.IsLargess" size="mini" class="marlt_5" type="danger">赠</el-tag>
                            <div class="color_red martp_5">￥ {{pro.Price | NumFormat}}</div>
                          </el-col>
                          <el-col :span="6">
                            <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,1,'')"></el-input-number>
                          </el-col>
                          <el-col :span="6">-</el-col>
                          <el-col :span="5">
                            <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageListClick(1,item,item.packageCard,packageIndex,pac,pac.Project,projectIndex,'','',ListIndex)"></el-button>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="24" class="pad_10 padbm_0 ">
                        <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(1,pac.Project,pro,projectIndex)">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                              <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                              <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                                <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <!-- 套餐卡产品 -->
                    <el-row v-for="(pro,productIndex) in pac.Product" :key="productIndex +'product'">
                      <el-col :span="24">
                        <el-row class="pad_10 border_bottom border_top">
                          <el-col :span="6" class="font_13">
                            <div>
                              {{pro.ProjectName}}
                              <span v-if="pro.Alias">({{pro.Alias}})</span>
                              <el-tag size="mini" class="marlt_5">产品</el-tag>
                              <el-tag size="mini" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                            </div>
                            <div class="color_red martp_5">￥ {{pro.Price | NumFormat}}</div>
                          </el-col>
                          <el-col :span="6">
                            <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,1,'')"></el-input-number>
                          </el-col>
                          <el-col :span="6">-</el-col>
                          <el-col :span="5">
                            <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                          </el-col>
                          <el-col :span="1">
                            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageListClick(2,item,item.packageCard,packageIndex,pac,pac.Product,productIndex,'','',ListIndex)"></el-button>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="24" class="pad_10 padbm_0 ">
                        <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(6,pac.Product,pro,productIndex)">
                          <el-col :span="4">
                            <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                              <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="20">
                            <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                              <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                                <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                  <template slot="append">%</template>
                                </el-input>
                                <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <!--套餐卡 通用次卡 -->
                    <el-row class="martp_5" v-for="(gen,generalIndex) in pac.GeneralCard" :key="generalIndex +'gencard'">
                      <div class="backf5f7fa pad_0_10  line_height_40">
                        <el-row>
                          <el-col :span="12" class="font_13">
                            {{gen.ProjectName}}
                            <span v-if="gen.Alias">({{gen.Alias}})</span>
                            <el-tag size="mini" class=" marlt_5">通用次卡</el-tag>
                          </el-col>
                          <el-col :span="12" class="text_right font_12 color_666">将退回：{{gen.TreatNumber}}次</el-col>
                        </el-row>
                      </div>
                      <el-row v-for="(pro,proindex) in gen.project" :key="proindex +'a'">
                        <el-col :span="24">
                          <el-row class="pad_10 border_bottom border_top">
                            <el-col :span="6" class="font_13">
                              <div>
                                {{pro.ProjectName}}
                                <span v-if="pro.Alias">({{pro.Alias}})</span>
                                <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                              </div>
                              <div class="color_red martp_5">
                                ￥ {{pro.Price | NumFormat}}
                                <span class="font_12 color_666 marlt_5">耗卡次数{{pro.onceTreatTimes}}</span>
                              </div>
                            </el-col>
                            <el-col :span="6">
                              <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,2,gen)"></el-input-number>
                            </el-col>
                            <el-col :span="6">-</el-col>
                            <el-col :span="5">
                              <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                            </el-col>
                            <el-col :span="1">
                              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageListClick(3,item,item.packageCard,packageIndex,pac,pac.GeneralCard,generalIndex,gen.project,proindex,ListIndex)"></el-button>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="24" class="pad_10 padbm_0 ">
                          <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(4,gen.project,pro,proindex)">
                            <el-col :span="4">
                              <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                                <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="20">
                              <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                                <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                                  <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                    <template slot="append">%</template>
                                  </el-input>
                                  <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-row>
                    <!-- 套餐卡时效卡 -->
                    <el-row class="martp_5" v-for="(time,timecardIndex) in pac.TimeCard" :key="timecardIndex +'times'">
                      <div class="backf5f7fa pad_0_10  line_height_40">
                        <el-row>
                          <el-col :span="12" class="font_13">
                            {{time.ProjectName}}
                            <span v-if="time.Alias">({{time.Alias}})</span>
                            <el-tag size="mini" class="marlt_5">时效卡</el-tag>
                          </el-col>
                          <el-col :span="12" class="text_right font_12 color_666"></el-col>
                        </el-row>
                      </div>
                      <el-row v-for="(pro,proindex) in time.project" :key="proindex +'a'">
                        <el-col :span="24">
                          <el-row class="pad_10 border_bottom border_top">
                            <el-col :span="6" class="font_13">
                              <div>
                                {{pro.ProjectName}}
                                <span v-if="pro.Alias">({{pro.Alias}})</span>
                                <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                              </div>
                              <div class="color_red martp_5">
                                ￥ {{pro.Price | NumFormat}}
                              </div>
                            </el-col>
                            <el-col :span="6">
                              <!-- <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,1,'')"></el-input-number> -->
                              <div class="font_13">{{pro.number}}</div>
                            </el-col>
                            <el-col :span="6">
                              <div class="font_13">
                                {{pro.LostWeight}} 斤
                              </div>
                            </el-col>
                            <el-col :span="5">
                              <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                            </el-col>
                            <el-col :span="1">
                              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageListClick(4,item,item.packageCard,packageIndex,pac,pac.TimeCard,timecardIndex,time.project,proindex,ListIndex)"></el-button>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="24" class="pad_10 padbm_0 ">
                          <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(3,time.project,pro,proindex)">
                            <el-col :span="4">
                              <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                                <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="20">
                              <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                                <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                                  <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                    <template slot="append">%</template>
                                  </el-input>
                                  <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-row>
                    <!-- 储值卡 -->
                    <el-row class="martp_5" v-for="(save,saveIndex) in pac.SavingCard" :key="saveIndex +'save'">
                      <div class="backf5f7fa pad_0_10  line_height_40">
                        <el-row>
                          <el-col :span="12" class="font_13">
                            {{save.ProjectName}}
                            <span v-if="save.Alias">({{save.Alias}})</span>
                            <el-tag size="mini" class=" marlt_5">储值卡</el-tag>
                          </el-col>
                          <el-col :span="12" class="text_right font_12 color_666"></el-col>
                        </el-row>
                      </div>
                      <el-row v-for="(pro,proindex) in save.project" :key="proindex +'a'">
                        <el-col :span="24">
                          <el-row class="pad_10 border_bottom border_top">
                            <el-col :span="6" class="font_13">
                              <div>
                                {{pro.ProjectName}}
                                <span v-if="pro.Alias">({{pro.Alias}})</span>
                                <el-tag size="mini" effect="plain" v-if="pro.IsLargess" class="marlt_5" type="danger">赠</el-tag>
                              </div>
                              <div class="color_red martp_5">
                                ￥ {{pro.Price | NumFormat}}
                              </div>
                            </el-col>
                            <el-col :span="6">
                              <el-input-number :min="1" size="mini" :max="pro.Quantity" style="width: 100px" v-model="pro.number" @change="numberChange(pro,3,'')"></el-input-number>
                            </el-col>
                            <el-col :span="6">-</el-col>
                            <el-col :span="5">
                              <el-input size="small" type="number" class="itemWidth" v-model="pro.TreatAmount" disabled></el-input>
                            </el-col>
                            <el-col :span="1">
                              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removePackageListClick(5,item,item.packageCard,packageIndex,pac,pac.SavingCard,saveIndex,save.project,proindex,ListIndex)"></el-button>
                            </el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="24" class="pad_10 padbm_0 ">
                          <el-row class="cursor_pointer" v-for="(handler,indexhand) in pro.handleTypeList" :key="indexhand +'hand'" @click.native="employeeHandleClick(2,save.project,pro,proindex)">
                            <el-col :span="4">
                              <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler" label-position="left">
                                <el-form-item style="margin-bottom:10px" :label="`${handler.Name}：`"></el-form-item>
                              </el-form>
                            </el-col>
                            <el-col :span="20">
                              <el-form :inline="true" size="mini" class="RefundTreatBillCreateHandler">
                                <el-form-item style="margin-bottom:10px" v-for="(employee ,handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName} [${employee.EmployeeID}]`">
                                  <el-input class="employee_num" size="mini" :min="0" :max="100" type="number" v-model="employee.Discount" v-on:click.native.stop v-enter-number style="width:125px;" @input="handlerPercentChange(handler.Employee,employee)">
                                    <template slot="append">%</template>
                                  </el-input>
                                  <i class="el-icon-error marlt_5" style="font-size: 20px;color:gray;vertical-align: middle" v-on:click.stop="removeHandleClick(handler,handleIndex)"></i>
                                </el-form-item>
                              </el-form>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-row>
                  </el-row>
                </el-row>

              </el-row>

            </el-scrollbar>
          </el-main>
          <el-footer class="border_top">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <span class="font_14 color_maroon">待退款金额：¥ {{TreatAmount | NumFormat}}</span>
              </el-col>
              <el-col :span="15">
                <el-input type="textarea" :rows="1" placeholder="请输入备注信息" v-model="Remake"></el-input>
              </el-col>
              <el-col :span="3" class="text_right">
                <el-button type="primary" size="small" @click="billClick">提交申请</el-button>
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>

    <!--经手人-->
    <el-dialog title="经手人" :visible.sync="beauticianVisible" width="800px">
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input placeholder="请输入员工编号、姓名" prefix-icon="el-icon-search" v-model="handlerName" size="small" clearable></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane :label="handler.Name" :name="`${index}`" v-for="(handler,index) in handlerList" :key="index">
            <el-row style="max-height: 300px;overflow-y: auto">
              <el-col :span="12" v-for="item in handler.Employee.filter(item => !handlerName || item.EmployeeName.toLowerCase().includes(handlerName.toLowerCase()) || item.EmployeeID.toLowerCase().includes(handlerName.toLowerCase())) " :key="item.EmployeeID" class="marbm_10 dis_flex flex_y_center">
                <el-checkbox v-model="item.Checked" @change="handlerCheckedChange(handler.Employee,item)">
                  <span class="marrt_10">{{item.EmployeeName}} [{{item.EmployeeID}}]</span>
                </el-checkbox>
                <el-input placeholder v-model="item.Discount" style="width: 120px" type="number" size="mini" min="0" max="100" v-enter-number @input="handlerPercentChange(handler.Employee,item)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="beauticianVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick" v-prevent-click>确 定</el-button>
      </div>
    </el-dialog>

    <!-- 提交申请 -->
    <el-dialog :visible.sync="withdrawDialog" width="350px">
      <div class="dis_flex flex_x_center flex_y_center flex_dir_column">
        <el-row>
          <i class="el-icon-document" style="font-size: 80px ;color: #999"></i>
        </el-row>
        <el-row>
          <el-col class="color_red font_24 martp_15">是否确认提交申请</el-col>
        </el-row>
        <el-row>
          <el-col class="martp_15">是否确认单据无误，确认后系统将生成单据审批后消耗的存量将退回顾客账户</el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="withdrawDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confrimWithdrawApplyClick" size="small" :loading="submitLoading" v-prevent-click>确定提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import APIR from "@/api/iBeauty/RefundBill/refundTreatBillCreate";
import API from "@/api/iBeauty/Order/saleGoods";
import APICustomer from "@/api/CRM/Customer/customer";
import APICustomerLevel from "@/api/CRM/Customer/customerLevel";
import APICustomerSource from "@/api/CRM/Customer/customerSource";
import validate from "@/components/js/validate.js";
import date from "@/components/js/date";

var Enumerable = require("linq");
export default {
  name: "RefundTreatBillCreate",
  components: {},
  data() {
    return {
      loading: false,
      isAddCustom: false,
      IsSupplement: false,
      submitLoading: false,
      BillDate: date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss"),
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now()
            // || time.getTime() < Date.now() - 3600 * 1000 * 24 * 7
          );
        },
      },
      customerName: "",
      customerID: null,
      customerFullName: "",
      customerPhoneNumber: "",
      modalLoading: false,
      customer: {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: "",
        CustomerLevelID: "",
        Code: "",
      },
      employee: [], //营销顾问
      customerLevel: [], //顾客等级
      customerSource: [], //顾客来源

      beauticianVisible: false,
      withdrawDialog: false,
      goodsName: "",
      GoodsList: [],
      TreatBillList: [],
      selectProject: [], // 项目
      selectSavingCard: [], // 储值卡
      selectTimeCard: [], // 时效卡
      selectGeneralCard: [], // 通用次卡
      selectProduct: [], // 套餐卡
      selectPackageCard: [], // 产品

      handlerList: [], //公共经手人列表
      productHandlerList: [], //产品经手人列表
      projectHandlerList: [], //项目经手人列表
      savingCardHandlerList: [], //储值卡经手人列表
      timeCardHandlerList: [], //时效卡经手人列表
      generalCardHandlerList: [], //通用次卡经手人列表
      packageCardHandlerList: [], //套餐卡经手人列表

      dialogVisible: false, // 经手人弹框
      handlerName: "", // 经手人搜索
      tabHandle: "0", // 经手人tabs

      Remake: "", //退款说明
      // TreatAmount: "",//总价

      Total: "",
    };
  },

  /**  计算属性  */
  computed: {
    TreatAmount: function () {
      var that = this;
      var Amount = 0;

      Enumerable.from(that.TreatBillList).forEach((TreatList) => {
        Amount += Enumerable.from(TreatList.project).sum((i) =>
          Number(i.TreatAmount)
        );
        // TreatList.project.forEach((pro) => {
        //   Amount += parseFloat(pro.TreatAmount);
        // });
        Amount += Enumerable.from(TreatList.product).sum((i) =>
          Number(i.TreatAmount)
        );
        // TreatList.product.forEach((pro) => {
        //   Amount += parseFloat(pro.TreatAmount);
        // });

        Enumerable.from(TreatList.generalCard).forEach((pro) => {
          Amount += Enumerable.from(pro.project).sum((i) =>
            Number(i.TreatAmount)
          );
          // pro.project.forEach((gen) => {
          //   Amount += parseFloat(gen.TreatAmount);
          // });
        });
        Enumerable.from(TreatList.savingCard).forEach((pro) => {
          Amount += Enumerable.from(pro.project).sum((i) =>
            Number(i.TreatAmount)
          );
          // pro.project.forEach((save) => {
          //   Amount += parseFloat(save.TreatAmount);
          // });
        });
        Enumerable.from(TreatList.timeCard).forEach((pro) => {
          Amount += Enumerable.from(pro.project).sum((i) =>
            Number(i.TreatAmount)
          );
          // pro.project.forEach((time) => {
          //   Amount += parseFloat(time.TreatAmount);
          // });
        });
        Enumerable.from(TreatList.packageCard).forEach((pack) => {
          Amount += Enumerable.from(pack.Project).sum((i) =>
            Number(i.TreatAmount)
          );
          // pack.Project.forEach((pro) => {
          //   Amount += parseFloat(pro.TreatAmount);
          // });
          Amount += Enumerable.from(pack.Product).sum((i) =>
            Number(i.TreatAmount)
          );
          // pack.Product.forEach((pro) => {
          //   Amount += parseFloat(pro.TreatAmount);
          // });

          Enumerable.from(pack.GeneralCard).forEach((gen) => {
            Amount += Enumerable.from(gen.project).sum((i) =>
              Number(i.TreatAmount)
            );
            // gen.project.forEach((p) => {
            //   Amount += parseFloat(p.TreatAmount);
            // });
          });
          Enumerable.from(pack.TimeCard).forEach((time) => {
            Amount += Enumerable.from(time.project).sum((i) =>
              Number(i.TreatAmount)
            );
            // time.project.forEach((p) => {
            //   Amount += parseFloat(p.TreatAmount);
            // });
          });
          Enumerable.from(pack.SavingCard).forEach((save) => {
            Amount += Enumerable.from(save.project).sum((i) =>
              Number(i.TreatAmount)
            );
            // save.project.forEach((p) => {
            //   Amount += parseFloat(p.TreatAmount);
            // });
          });
        });
      });

      return Amount;
    },
  },

  methods: {
    /**  顾客选择  */
    // 顾客

    saleCustomerData: function (queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      API.getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  选择顾客  */
    handleCustomerSelect(item) {
      var that = this;
      if (item.ID != undefined) {
        that.customerID = item.ID;
        that.customerFullName = item.Name;
        that.customerPhoneNumber = item.PhoneNumber;
        that.customerName = item.Name + "【" + item.PhoneNumber + "】";
        that.getRefundBillList();
      } else {
        // that.addNewCustomer();
      }
    },
    /**  删除会员  */
    removeCustomer() {
      var that = this;
      this.customerID = null;
      this.customerFullName = "";
      this.customerPhoneNumber = "";
      this.customerName = "";
      that.GoodsList = [];
      that.TreatBillList = [];
    },
    /**  会员数据  */
    mathAbsData: function (item) {
      return Math.abs(item);
    },

    // 新增顾客
    addNewCustomer: function () {
      var that = this;
      that.ruleForm = {
        Name: "",
        PhoneNumber: "",
        Gender: "2",
        CustomerSourceID: null,
        EmployeeID: "",
        CustomerLevelID: "",
        Code: "",
      };
      that.isAddCustom = true;
      that.employeeData();
    },
    // 保存新增客户
    submitCustomer: function () {
      var that = this;
      this.$refs.customer.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          let para = Object.assign({}, that.customer);
          APICustomer.createCustomer(para)
            .then(function (res) {
              if (res.StateCode === 200) {
                that.$message.success({
                  message: "新增成功",
                  duration: 2000,
                });
                that.customerID = res.Data.ID;
                that.customerFullName = res.Data.Name;
                that.customerPhoneNumber = res.Data.PhoneNumber;
                that.customerName =
                  res.Data.Name + "【" + res.Data.PhoneNumber + "】";
                that.getRefundBillList();
                that.$refs["customer"].resetFields();
                that.isAddCustom = false;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        }
      });
    },
    //客户所属顾问
    employeeData: function () {
      var that = this;
      APICustomer.getConsultant()
        .then((res) => {
          if (res.StateCode == 200) {
            that.employee = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 顾客来源
    CustomerSourceData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerSource.getCustomerSource(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerSource = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 顾客等级
    CustomerLevelData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerLevel.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevel = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    isSupplementChange: function () {
      var that = this;
      if (!that.IsSupplement) {
        return;
      } else {
        that.BillDate = null;
      }
    },
    /**  退消耗操作  */
    clearClick() {
      var that = this;
      that.getRefundBillList();
    },
    /**  搜索  */
    searchGoodsClick() {
      var that = this;
      if (that.customerID == null) {
        that.$message.error({
          message: "请选择会员",
          duration: 2000,
        });
      } else {
        that.getRefundBillList();
      }
    },
    //  load(){
    //      this.getRefundBillList()
    // },
    getRefundBillList() {
      var that = this;
      that.loading = true;
      // that.pageNum=that.pageNum+1
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      APIR.GetTreatList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            // res.List.forEach(item =>{
            //   that.GoodsList.push(item)
            // })
            that.GoodsList = res.Data;
            // that.Total =res.Total
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    clearAll() {
      var that = this;
      that.TreatBillList = [];
      that.GoodsList = [];
      (that.Remake = ""), (that.TreatAmount = 0);
    },

    /**  开单时间  */
    getBillDate: function () {
      var that = this;
      return that.isSupplement
        ? that.BillDate
        : date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss");
    },

    // 项目经手人
    projectHandlerData: function () {
      var that = this;
      that.loading = true;
      var params = {};
      APIR.treatGoodsProjectHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 产品经手人
    productHandlerData: function () {
      var that = this;
      that.loading = true;
      var params = {};
      APIR.treatGoodsProductHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 储值卡经手人
    savingCardHandlerData: function () {
      var that = this;
      that.loading = true;
      var params = {};
      APIR.treatGoodsSavingCardHandler(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 经手人
    employeeHandleClick: function (type, row, item, index) {
      var that = this;
      that.tabHandle = "0";
      var emplayee = [];
      switch (type) {
        case 1:
          that.handlerList = that.projectHandlerList;
          break;
        case 2:
          that.handlerList = that.savingCardHandlerList;
          break;
        case 3:
          that.handlerList = that.projectHandlerList;
          break;
        case 4:
          that.handlerList = that.projectHandlerList;
          break;
        case 5:
          that.handlerList = that.projectHandlerList;
          break;
        case 6:
          that.handlerList = that.productHandlerList;
          break;
      }
      item.handleTypeList.forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          emplayee.push({ ID: emp.ID, Discount: emp.Discount });
        });
      });
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          emp.Checked = false;
          emp.Discount = "";
          emplayee.forEach(function (i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });
      that.type = type;
      that.selectGoods = row;
      that.goodsIndex = index;
      that.beauticianVisible = true;
    },
    // 百分比
    handlerPercentChange: function (row, item) {
      var that = this;
      var discount = 0;
      if (item.Discount != "") {
        item.Discount = parseFloat(item.Discount);
      }
      if (item.Discount > 100) {
        item.Discount = 100;
      }
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
    },
    // 经手人选择
    handlerCheckedChange: function (row, item) {
      var that = this;
      var discount = 0;
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
    },
    // 经手人确认选择
    submitHandleClick: function () {
      var that = this;
      var goodsHandlerList = JSON.parse(JSON.stringify(that.handlerList));
      goodsHandlerList.forEach(function (item) {
        item.Employee = Enumerable.from(item.Employee)
          .where(function (i) {
            return i.Checked;
          })
          .toArray();
      });
      that.selectGoods[that.goodsIndex].handleTypeList = goodsHandlerList;
      switch (that.type) {
        case 1:
          that.selectProject = that.selectGoods;
          break;
        case 2:
          that.selectSavingCard = that.selectGoods;
          break;
        case 3:
          that.selectTimeCard = that.selectGoods;
          break;
        case 4:
          that.selectGeneralCard = that.selectGoods;
          break;
        case 5:
          that.selectPackageCard = that.selectGoods;
          break;
        case 6:
          that.selectProduct = that.selectGoods;
          break;
      }
      that.beauticianVisible = false;
    },
    // 删除经手人
    removeHandleClick: function (item, index) {
      item.Employee.splice(index, 1);
    },
    billClick() {
      var that = this;
      if (that.BillDate == "" || that.BillDate == null) {
        that.$message.error("请选择开单时间！");
        return;
      }
      if (that.TreatBillList.length == 0) {
        that.$message.error("您还未选择商品");
        return;
      }
      if (that.customerID == null) {
        that.$message.error("请选择顾客！");
        return;
      } else {
        that.withdrawDialog = true;
      }
    },
    //清除
    clearTreatList() {
      var that = this;
      that.TreatBillList = [];
      that.Remake = "";
      // that.TreatAmount = 0
    },
    //点击项目 产品
    clickInsertList(item, type, goods) {
      console.log("clickInsertList -> item", item);
      var that = this;
      switch (type) {
        case 0:
          var project = {
            number: item.Quantity,
            TreatAmount: item.TotalAmount,
            TotalAmount: item.TotalAmount,
            ProjectID: item.ProjectID,
            ProjectName: item.ProjectName,
            Alias: item.Alias,
            Amount: item.Amount,
            UnitAmount: Number(item.TotalAmount) / Number(item.Quantity),
            IsLargess: item.IsLargess,
            Price: item.Price,
            ProjectAccountID: item.ProjectAccountID,
            Quantity: item.Quantity,
            TreatBillID: item.TreatBillID,
            TreatBillProjectID: item.TreatBillProjectID,
            handleTypeList: [],
          };
          project.handleTypeList = Enumerable.from(that.projectHandlerList)
            .select((item) => ({
              ID: item.ID,
              Name: item.Name,
              Employee: [],
            }))
            .toArray();
          //  console.log(project);
          var tempOrderItem = Enumerable.from(
            that.TreatBillList
          ).firstOrDefault((i) => {
            return i.ID == goods.ID;
          }, -1);
          if (tempOrderItem == -1) {
            tempOrderItem = {
              BillDate: goods.BillDate,
              ID: goods.ID,
              project: [project],
              product: [],
              generalCard: [],
              savingCard: [],
              timeCard: [],
              packageCard: [],
            };
            that.TreatBillList.push(tempOrderItem);
          } else {
            var tempProject = Enumerable.from(
              tempOrderItem.project
            ).firstOrDefault((i) => {
              return i.TreatBillProjectID == item.TreatBillProjectID;
            }, -1);
            if (tempProject == -1) {
              tempOrderItem.project.push(project);
            } else {
              that.$message.error({
                message: "项目已存在",
                duration: 2000,
              });
            }
          }

          break;
        case 1:
          var product = {
            number: item.Quantity,
            TreatAmount: item.TotalAmount,
            TotalAmount: item.TotalAmount,
            ProductID: item.ProductID,
            ProjectName: item.ProductName,
            Alias: item.Alias,
            Amount: item.Amount,
            UnitAmount: Number(item.TotalAmount) / Number(item.Quantity),
            IsLargess: item.IsLargess,
            Price: item.Price,
            ProductAccountID: item.ProductAccountID,
            Quantity: item.Quantity,
            TreatBillID: item.TreatBillID,
            TreatBillProductID: item.TreatBillProductID,
            handleTypeList: [],
          };
          product.handleTypeList = Enumerable.from(that.productHandlerList)
            .select((item) => ({
              ID: item.ID,
              Name: item.Name,
              Employee: [],
            }))
            .toArray();
          var temppOrderItem = Enumerable.from(
            that.TreatBillList
          ).firstOrDefault((i) => {
            return i.ID == goods.ID;
          }, -1);
          if (temppOrderItem == -1) {
            temppOrderItem = {
              BillDate: goods.BillDate,
              ID: goods.ID,
              project: [],
              product: [product],
              generalCard: [],
              savingCard: [],
              timeCard: [],
              packageCard: [],
            };
            that.TreatBillList.push(temppOrderItem);
          } else {
            var tempProduct = Enumerable.from(
              temppOrderItem.product
            ).firstOrDefault((i) => {
              return i.TreatBillProductID == item.TreatBillProductID;
            }, -1);
            if (tempProduct == -1) {
              temppOrderItem.product.push(product);
            } else {
              that.$message.error({
                message: "产品已存在",
                duration: 2000,
              });
            }
          }
          break;
      }
    },
    // 点击通用次卡  时效卡 储值卡
    clickInsertCardList(item, row, type, BillDate, ID, goods) {
      var that = this;
      var orderItem = Enumerable.from(that.TreatBillList).firstOrDefault(
        (i) => {
          return i.ID == goods.ID;
        },
        -1
      );
      switch (type) {
        case 2:
          /**  项目  */
          var cardList = {
            number: item.Quantity,
            TreatAmount: item.TotalAmount,
            TotalAmount: item.TotalAmount,
            id: item.ProjectID,
            ProjectName: item.ProjectName,
            Alias: item.Alias,
            CardTreatTimes: item.CardTreatTimes,
            onceTreatTimes: item.CardTreatTimes / item.Quantity,
            IsLargess: item.IsLargess,
            Price: item.Price,
            Quantity: item.Quantity,
            Amount: item.Amount,
            TreatBillGeneralCardID: item.TreatBillGeneralCardID,
            handleTypeList: [],
          };
          /** 通用次卡  */
          var genralCard = {
            GeneralCardAccountID: row.GeneralCardAccountID,
            GeneralCardID: row.GeneralCardID,
            ProjectName: row.GeneralCardName,
            TreatBillID: row.TreatBillID,
            Alias: row.Alias,
            TreatNumber: cardList.Quantity * cardList.onceTreatTimes,
            project: [cardList],
          };
          /**  经手人  */
          cardList.handleTypeList = Enumerable.from(that.projectHandlerList)
            .select((i) => ({
              ID: i.ID,
              Name: i.Name,
              Employee: [],
            }))
            .toArray();

          /**  判断该笔订单是否存在  */

          if (orderItem == -1) {
            that.TreatBillList.push({
              BillDate: goods.BillDate,
              ID: goods.ID,
              product: [],
              project: [],
              generalCard: [genralCard],
              savingCard: [],
              timeCard: [],
              packageCard: [],
            });
          } else {
            /**  判断 订单中通用次卡是否存在  */
            var generalItem = Enumerable.from(
              orderItem.generalCard
            ).firstOrDefault((i) => {
              return i.GeneralCardAccountID == row.GeneralCardAccountID;
            }, -1);
            if (generalItem == -1) {
              orderItem.generalCard.push(genralCard);
            } else {
              /**  判断 通用次卡中 项目是否存在  */
              var generalProject = Enumerable.from(
                generalItem.project
              ).firstOrDefault((i) => {
                return i.TreatBillGeneralCardID == item.TreatBillGeneralCardID;
              }, -1);
              if (generalProject == -1) {
                generalItem.project.push(cardList);
              } else {
                that.$message.error({
                  message: "项目已存在",
                });
                return;
              }
            }
          }

          break;
        case 3:
          var timeProject = {
            number: item.Quantity,
            LostWeight:item.LostWeight,
            TreatAmount: item.TotalAmount,
            id: item.ProjectID,
            ProjectName: item.ProjectName,
            Alias: item.Alias,
            IsLargess: item.IsLargess,
            Price: item.Price,
            Amount: item.Amount,
            TotalAmount: item.TotalAmount,
            Quantity: item.Quantity,
            TreatBillTimeCardID: item.TreatBillTimeCardID,
            handleTypeList: [],
          };
          var timeCard = {
            TimeCardID: row.TimeCardID,
            ProjectName: row.TimeCardName,
            PackageCardAccountID: row.PackageCardAccountID,
            TreatBillID: row.TreatBillID,
            Alias: row.Alias,
            TimeCardAccountID: row.TimeCardAccountID,
            project: [timeProject],
          };
          /**  经手人  */
          timeProject.handleTypeList = Enumerable.from(
            that.projectHandlerList
          ).select((i) => ({
            ID: i.ID,
            Name: i.Name,
            Employee: [],
          }));

          /**    */
          if (orderItem == -1) {
            that.TreatBillList.push({
              BillDate: goods.BillDate,
              ID: goods.ID,
              product: [],
              project: [],
              generalCard: [],
              savingCard: [],
              timeCard: [timeCard],
              packageCard: [],
            });
          } else {
            var timerCardItem = Enumerable.from(
              orderItem.timeCard
            ).firstOrDefault((i) => {
              return i.TimeCardAccountID == row.TimeCardAccountID;
            }, -1);
            if (timerCardItem == -1) {
              orderItem.timeCard.push(timeCard);
            } else {
              var timerCardProject = Enumerable.from(
                timerCardItem.project
              ).firstOrDefault((i) => {
                return i.TreatBillTimeCardID == item.TreatBillTimeCardID;
              }, -1);

              if (timerCardProject == -1) {
                timerCardItem.project.push(timeProject);
              } else {
                that.$message.error({
                  message: "项目已存在",
                });
                return;
              }
            }
          }

          break;
        case 4:
          var savingProject = {
            number: item.Quantity,
            TreatAmount: item.TotalAmount,
            id: item.ProjectID,
            ProjectName: item.ProjectName,
            Alias: item.Alias,
            IsLargess: item.IsLargess,
            Price: item.Price,
            Quantity: item.Quantity,
            Amount: item.Amount,
            TotalAmount: item.TotalAmount,
            CardPreferentialAmount: item.CardPreferentialAmount,
            TreatBillSavingCardID: item.TreatBillSavingCardID,
            handleTypeList: [],
          };
          var SavingCard = {
            SavingCardID: row.SavingCardID,
            ProjectName: row.SavingCardName,
            TreatBillID: row.TreatBillID,
            Alias: row.Alias,
            SavingCardAccountID: row.SavingCardAccountID,
            project: [savingProject],
          };
          savingProject.handleTypeList = Enumerable.from(
            that.savingCardHandlerList
          ).select((item) => ({
            ID: item.ID,
            Name: item.Name,
            Employee: [],
          }));

          /**    */
          if (orderItem == -1) {
            that.TreatBillList.push({
              BillDate: goods.BillDate,
              ID: goods.ID,
              product: [],
              project: [],
              generalCard: [],
              savingCard: [SavingCard],
              timeCard: [],
              packageCard: [],
            });
          } else {
            var savingCardItem = Enumerable.from(
              orderItem.savingCard
            ).firstOrDefault((i) => {
              return i.SavingCardAccountID == row.SavingCardAccountID;
            }, -1);
            if (savingCardItem == -1) {
              orderItem.savingCard.push(SavingCard);
            } else {
              var savingCardProject = Enumerable.from(
                savingCardItem.project
              ).firstOrDefault((i) => {
                return i.TreatBillSavingCardID == item.TreatBillSavingCardID;
              }, -1);
              if (savingCardProject == -1) {
                savingCardItem.project.push(savingProject);
              } else {
                that.$message.error({
                  message: "项目已存在",
                });
                return;
              }
            }
          }
          break;
      }
    },
    //点击套餐卡
    /**  */
    clickInsertPackageCard(cardType, goods, packageItem, cardItem, project) {
      var that = this;

      var orderItem = Enumerable.from(that.TreatBillList).firstOrDefault(
        (i) => {
          return i.ID == goods.ID;
        },
        -1
      );
      if (orderItem == -1) {
        orderItem = {
          BillDate: goods.BillDate,
          ID: goods.ID,
          product: [],
          project: [],
          generalCard: [],
          savingCard: [],
          timeCard: [],
          packageCard: [],
        };
        that.TreatBillList.push(orderItem);
      }

      var tempPackageItem = Enumerable.from(
        orderItem.packageCard
      ).firstOrDefault((i) => {
        return i.PackageCardAccountID == packageItem.PackageCardAccountID;
      }, -1);
      if (tempPackageItem == -1) {
        tempPackageItem = {
          PackageCardName: packageItem.PackageCardName,
          Alias: packageItem.Alias,
          PackageCardAccountID: packageItem.PackageCardAccountID,
          PackageCardID: packageItem.PackageCardID,
          TreatBillID: packageItem.TreatBillID,
          Project: [],
          Product: [],
          GeneralCard: [],
          SavingCard: [],
          TimeCard: [],
        };
        orderItem.packageCard.push(tempPackageItem);
      }

      switch (cardType) {
        case 0: //项目
          var tempProjectItem = Enumerable.from(
            tempPackageItem.Project
          ).firstOrDefault((i) => {
            return i.TreatBillProjectID == cardItem.TreatBillProjectID;
          }, -1);
          if (tempProjectItem == -1) {
            tempProjectItem = {
              number: cardItem.Quantity,
              TreatAmount: cardItem.TotalAmount,
              Alias: cardItem.Alias,
              Amount: cardItem.Amount,
              TotalAmount: cardItem.TotalAmount,
              IsLargess: cardItem.IsLargess,
              PackageCardAccountID: cardItem.PackageCardAccountID,
              Price: cardItem.Price,
              UnitAmount:
                Number(cardItem.TotalAmount) / Number(cardItem.Quantity),
              ProjectAccountID: cardItem.ProjectAccountID,
              ProjectID: cardItem.ProjectID,
              ProjectName: cardItem.ProjectName,
              Quantity: cardItem.Quantity,
              TreatBillID: cardItem.TreatBillID,
              TreatBillProjectID: cardItem.TreatBillProjectID,
              handleTypeList: [],
            };
            tempProjectItem.handleTypeList = Enumerable.from(
              that.projectHandlerList
            )
              .select((i) => ({
                ID: i.ID,
                Name: i.Name,
                Employee: [],
              }))
              .toArray();

            tempPackageItem.Project.push(tempProjectItem);
          } else {
            that.$message.error({
              message: "项目已存在",
              duration: 2000,
            });
            return;
          }
          break;

        case 1: //产品
          var tempProductItem = Enumerable.from(
            tempPackageItem.Product
          ).firstOrDefault((i) => {
            return i.TreatBillProductID == cardItem.TreatBillProductID;
          }, -1);
          if (tempProductItem == -1) {
            tempProductItem = {
              number: cardItem.Quantity,
              TreatAmount: cardItem.TotalAmount,
              Alias: cardItem.Alias,
              Amount: cardItem.Amount,
              TotalAmount: cardItem.TotalAmount,
              IsLargess: cardItem.IsLargess,
              PackageCardAccountID: cardItem.PackageCardAccountID,
              Price: cardItem.Price,
              UnitAmount:
                Number(cardItem.TotalAmount) / Number(cardItem.Quantity),
              ProjectAccountID: cardItem.ProjectAccountID,
              ProductID: cardItem.ProductID,
              ProjectName: cardItem.ProductName,
              Quantity: cardItem.Quantity,
              ProductAccountID: cardItem.ProductAccountID,
              TreatBillID: cardItem.TreatBillID,
              TreatBillProductID: cardItem.TreatBillProductID,
              handleTypeList: [],
            };

            tempProductItem.handleTypeList = Enumerable.from(
              that.productHandlerList
            )
              .select((i) => ({
                ID: i.ID,
                Name: i.Name,
                Employee: [],
              }))
              .toArray();

            tempPackageItem.Product.push(tempProductItem);
          } else {
            that.$message.error({
              message: "产品已存在",
              duration: 2000,
            });
            return;
          }
          break;

        case 2: //通用次卡
          var tempGeneralItem = Enumerable.from(
            tempPackageItem.GeneralCard
          ).firstOrDefault((i) => {
            return i.GeneralCardAccountID == cardItem.GeneralCardAccountID;
          }, -1);
          var generalProject = {
            number: project.Quantity,
            TreatAmount: project.TotalAmount,
            Alias: project.Alias,
            Amount: project.Amount,
            TotalAmount: project.TotalAmount,
            IsLargess: project.IsLargess,
            Price: project.Price,
            ProjectID: project.ProjectID,
            ProjectName: project.ProjectName,
            CardTreatTimes: project.CardTreatTimes,
            onceTreatTimes: project.CardTreatTimes / project.Quantity,
            Quantity: project.Quantity,
            TreatBillGeneralCardID: project.TreatBillGeneralCardID,
            handleTypeList: [],
          };

          generalProject.handleTypeList = Enumerable.from(
            that.projectHandlerList
          )
            .select((i) => ({
              ID: i.ID,
              Name: i.Name,
              Employee: [],
            }))
            .toArray();

          if (tempGeneralItem == -1) {
            tempGeneralItem = {
              GeneralCardID: cardItem.GeneralCardID,
              ProjectName: cardItem.GeneralCardName,
              GeneralCardAccountID: cardItem.GeneralCardAccountID,
              Alias: cardItem.Alias,
              TreatBillID: cardItem.TreatBillID,
              TreatNumber:
                generalProject.Quantity * generalProject.onceTreatTimes,
              project: [generalProject],
            };
            tempPackageItem.GeneralCard.push(tempGeneralItem);
          } else {
            var tempGeneralProject = Enumerable.from(
              tempGeneralItem.project
            ).firstOrDefault((i) => {
              return i.TreatBillGeneralCardID == project.TreatBillGeneralCardID;
            }, -1);
            if (tempGeneralProject == -1) {
              tempGeneralItem.project.push(generalProject);

              tempGeneralItem.TreatNumber = Enumerable.from(
                tempGeneralItem.project
              ).sum((i) => Number(i.Quantity) * Number(i.onceTreatTimes));
            } else {
              that.$message.error({
                message: "项目已存在",
                duration: 2000,
              });
              return;
            }
          }
          break;
        case 3: //时效卡
          var tempTimeItem = Enumerable.from(
            tempPackageItem.TimeCard
          ).firstOrDefault((i) => {
            return i.TimeCardAccountID == cardItem.TimeCardAccountID;
          }, -1);
          var timeCardProject = {
            number: project.Quantity,
            LostWeight:project.LostWeight,
            TreatAmount: project.TotalAmount,
            Alias: project.Alias,
            Amount: project.Amount,
            TotalAmount: project.TotalAmount,
            IsLargess: project.IsLargess,
            Price: project.Price,
            ProjectID: project.ProjectID,
            ProjectName: project.ProjectName,
            Quantity: project.Quantity,
            TreatBillTimeCardID: project.TreatBillTimeCardID,
            handleTypeList: [],
          };

          timeCardProject.handleTypeList = Enumerable.from(
            that.projectHandlerList
          )
            .select((i) => ({
              ID: i.ID,
              Name: i.Name,
              Employee: [],
            }))
            .toArray();

          if (tempTimeItem == -1) {
            tempTimeItem = {
              TimeCardID: cardItem.TimeCardID,
              ProjectName: cardItem.TimeCardName,
              TimeCardAccountID: cardItem.TimeCardAccountID,
              Alias: cardItem.Alias,
              project: [timeCardProject],
            };
            tempPackageItem.TimeCard.push(tempTimeItem);
          } else {
            var tempTimeProject = Enumerable.from(
              tempTimeItem.project
            ).firstOrDefault((i) => {
              return i.TreatBillTimeCardID == project.TreatBillTimeCardID;
            }, -1);

            if (tempTimeProject == -1) {
              tempTimeItem.project.push(timeCardProject);
            } else {
              that.$message.error({
                message: "项目已存在",
                duration: 2000,
              });
              return;
            }
          }
          break;

        case 4: //储值卡
          var tempSavingItem = Enumerable.from(
            tempPackageItem.SavingCard
          ).firstOrDefault((i) => {
            return i.SavingCardAccountID == cardItem.SavingCardAccountID;
          }, -1);

          var savingCardProject = {
            number: project.Quantity,
            TreatAmount: project.TotalAmount,
            Alias: project.Alias,
            Amount: project.Amount,
            TotalAmount: project.TotalAmount,
            IsLargess: project.IsLargess,
            Price: project.Price,
            UnitAmount: Number(project.TotalAmount) / Number(project.Quantity),
            ProjectID: project.ProjectID,
            ProjectName: project.ProjectName,
            Quantity: project.Quantity,
            CardPreferentialAmount: project.CardPreferentialAmount,
            TreatBillSavingCardID: project.TreatBillSavingCardID,
            handleTypeList: [],
          };
          savingCardProject.handleTypeList = Enumerable.from(
            that.savingCardHandlerList
          )
            .select((i) => ({
              ID: i.ID,
              Name: i.Name,
              Employee: [],
            }))
            .toArray();

          if (tempSavingItem == -1) {
            tempSavingItem = {
              SavingCardID: cardItem.SavingCardID,
              ProjectName: cardItem.SavingCardName,
              SavingCardAccountID: cardItem.SavingCardAccountID,
              Alias: cardItem.Alias,
              project: [savingCardProject],
            };
            tempPackageItem.SavingCard.push(tempSavingItem);
          } else {
            var tempSavingCardProject = Enumerable.from(
              tempSavingItem.project
            ).firstOrDefault((i) => {
              return i.TreatBillSavingCardID == project.TreatBillSavingCardID;
            }, -1);

            if (tempSavingCardProject == -1) {
              tempSavingItem.project.push(savingCardProject);
            } else {
              that.$message.error({
                message: "项目已存在",
                duration: 2000,
              });
              return;
            }
          }
          break;
      }
    },
    removePackageClick(
      type,
      item,
      ChildList,
      cardList,
      cardIndex,
      project,
      proIndex,
      indexChild
    ) {
      var that = this;
      switch (type) {
        case 1:
          if (
            project.project.length == 1 &&
            project.product.length <= 0 &&
            project.timeCard.length <= 0 &&
            project.generalCard.length <= 0 &&
            project.savingCard.length <= 0 &&
            project.packageCard.length <= 0
          ) {
            that.TreatBillList.splice(proIndex, 1);
          } else {
            item.splice(cardIndex, 1);
          }
          break;
        case 2:
          if (
            project.project.length == 0 &&
            project.product.length <= 1 &&
            project.timeCard.length <= 0 &&
            project.generalCard.length <= 0 &&
            project.savingCard.length <= 0 &&
            project.packageCard.length <= 0
          ) {
            that.TreatBillList.splice(proIndex, 1);
          } else {
            item.splice(cardIndex, 1);
          }
          break;
        case 3:
          if (
            project.project.length == 0 &&
            project.product.length <= 0 &&
            project.timeCard.length <= 0 &&
            project.generalCard.length == 1 &&
            project.savingCard.length <= 0 &&
            project.packageCard.length <= 0 &&
            ChildList.length == 1
          ) {
            that.TreatBillList.splice(proIndex, 1);
          } else if (ChildList.length > 1) {
            ChildList.splice(cardIndex, 1);
          } else {
            item.splice(indexChild, 1);
          }
          break;
        case 4:
          if (
            project.project.length == 0 &&
            project.product.length <= 0 &&
            project.timeCard.length == 1 &&
            project.generalCard.length <= 0 &&
            project.savingCard.length <= 0 &&
            project.packageCard.length <= 0 &&
            ChildList.length == 1
          ) {
            that.TreatBillList.splice(proIndex, 1);
          } else if (ChildList.length > 1) {
            ChildList.splice(cardIndex, 1);
          } else {
            item.splice(indexChild, 1);
          }
          break;
        case 5:
          if (
            project.project.length == 0 &&
            project.product.length <= 0 &&
            project.timeCard.length <= 0 &&
            project.generalCard.length <= 0 &&
            project.savingCard.length == 1 &&
            project.packageCard.length <= 0 &&
            ChildList.length == 1
          ) {
            that.TreatBillList.splice(proIndex, 1);
          } else if (ChildList.length > 1) {
            ChildList.splice(cardIndex, 1);
          } else {
            item.splice(indexChild, 1);
          }
          break;
      }
    },
    //删除套餐卡item,item.packageCard,packageIndex,pac.GeneralCard,generalIndex,gen.project,proindex
    removePackageListClick(
      type,
      item,
      packageList,
      packageIndex,
      Child,
      ItemChildList,
      ItemChildListIndex,
      ChildProject,
      ChildProjectIndex,
      treatListIndex
    ) {
      var that = this;
      switch (type) {
        case 1:
          if (
            item.packageCard.length == 1 &&
            item.generalCard.length <= 0 &&
            item.product.length <= 0 &&
            item.project.length <= 0 &&
            item.savingCard.length <= 0 &&
            item.timeCard.length <= 0 &&
            Child.GeneralCard.length <= 0 &&
            Child.TimeCard.length <= 0 &&
            Child.Project.length == 1 &&
            Child.Product.length <= 0 &&
            Child.SavingCard.length <= 0
          ) {
            that.TreatBillList.splice(treatListIndex, 1);
          } else if (
            item.packageCard.length == 1 &&
            Child.Project.length == 1 &&
            Child.GeneralCard.length <= 0 &&
            Child.TimeCard.length <= 0 &&
            Child.Product.length <= 0 &&
            Child.SavingCard.length <= 0
          ) {
            packageList.splice(packageIndex, 1);
          } else if (packageList.length > 1 && ItemChildList.length == 1) {
            packageList.splice(packageIndex, 1);
          } else {
            ItemChildList.splice(ItemChildListIndex, 1);
          }
          break;
        case 2:
          if (
            item.packageCard.length == 1 &&
            item.generalCard.length <= 0 &&
            item.product.length <= 0 &&
            item.project.length <= 0 &&
            item.savingCard.length <= 0 &&
            item.timeCard.length <= 0 &&
            Child.GeneralCard.length <= 0 &&
            Child.TimeCard.length <= 0 &&
            Child.Project.length <= 0 &&
            Child.Product.length == 1 &&
            Child.SavingCard.length <= 0
          ) {
            that.TreatBillList.splice(treatListIndex, 1);
          } else if (
            item.packageCard.length == 1 &&
            Child.Project.length <= 0 &&
            Child.GeneralCard.length <= 0 &&
            Child.TimeCard.length <= 0 &&
            Child.Product.length == 1 &&
            Child.SavingCard.length <= 0
          ) {
            packageList.splice(packageIndex, 1);
          } else if (packageList.length > 1 && ItemChildList.length == 1) {
            packageList.splice(packageIndex, 1);
          } else {
            ItemChildList.splice(ItemChildListIndex, 1);
          }
          break;
        case 3:
          if (
            item.packageCard.length == 1 &&
            item.generalCard.length <= 0 &&
            item.product.length <= 0 &&
            item.project.length <= 0 &&
            item.savingCard.length <= 0 &&
            item.timeCard.length <= 0 &&
            Child.GeneralCard.length == 1 &&
            Child.TimeCard.length <= 0 &&
            Child.Project.length <= 0 &&
            Child.Product.length <= 0 &&
            Child.SavingCard.length <= 0 &&
            ChildProject.length == 1
          ) {
            that.TreatBillList.splice(treatListIndex, 1);
          } else if (ChildProject.length > 1) {
            ChildProject.splice(ChildProjectIndex, 1);
          } else if (
            item.packageCard.length == 1 &&
            ItemChildList.length == 1 &&
            Child.Project.length == 0 &&
            Child.GeneralCard.length == 1 &&
            Child.TimeCard.length == 0 &&
            Child.Project.length == 0 &&
            Child.Product.length == 0 &&
            Child.SavingCard.length == 0
          ) {
            packageList.splice(packageIndex, 1);
          } else if (packageList.length > 1 && ItemChildList.length == 1) {
            packageList.splice(packageIndex, 1);
          } else {
            Child.GeneralCard.splice(ItemChildListIndex, 1);
          }
          break;
        case 4:
          if (
            item.packageCard.length == 1 &&
            item.generalCard.length <= 0 &&
            item.product.length <= 0 &&
            item.project.length <= 0 &&
            item.savingCard.length <= 0 &&
            item.timeCard.length <= 0 &&
            Child.Project.length <= 0 &&
            Child.GeneralCard.length <= 0 &&
            Child.TimeCard.length == 1 &&
            Child.Product.length <= 0 &&
            Child.SavingCard.length <= 0 &&
            ChildProject.length == 1
          ) {
            that.TreatBillList.splice(treatListIndex, 1);
          } else if (ChildProject.length > 1) {
            ChildProject.splice(ChildProjectIndex, 1);
          } else if (
            item.packageCard.length == 1 &&
            ItemChildList.length == 1 &&
            Child.Project.length == 0 &&
            Child.GeneralCard.length == 0 &&
            Child.TimeCard.length == 1 &&
            Child.Project.length == 0 &&
            Child.Product.length == 0 &&
            Child.SavingCard.length == 0
          ) {
            packageList.splice(packageIndex, 1);
          } else if (packageList.length > 1 && ItemChildList.length == 1) {
            packageList.splice(packageIndex, 1);
          } else {
            Child.TimeCard.splice(ItemChildListIndex, 1);
          }
          break;
        case 5:
          if (
            item.packageCard.length == 1 &&
            item.generalCard.length <= 0 &&
            item.product.length <= 0 &&
            item.project.length <= 0 &&
            item.savingCard.length <= 0 &&
            item.timeCard.length <= 0 &&
            Child.Project.length <= 0 &&
            Child.GeneralCard.length <= 0 &&
            Child.TimeCard.length <= 0 &&
            Child.Product.length <= 0 &&
            Child.SavingCard.length == 1 &&
            ChildProject.length == 1
          ) {
            that.TreatBillList.splice(treatListIndex, 1);
          } else if (ChildProject.length > 1) {
            ChildProject.splice(ChildProjectIndex, 1);
          } else if (
            item.packageCard.length == 1 &&
            ItemChildList.length == 1 &&
            Child.Project.length == 0 &&
            Child.GeneralCard.length == 0 &&
            Child.TimeCard.length == 0 &&
            Child.Project.length == 0 &&
            Child.Product.length == 0 &&
            Child.SavingCard.length == 1
          ) {
            packageList.splice(packageIndex, 1);
          } else if (packageList.length > 1 && ItemChildList.length == 1) {
            packageList.splice(packageIndex, 1);
          } else {
            Child.SavingCard.splice(ItemChildListIndex, 1);
          }
          break;
      }
    },
    //数量改变
    numberChange(item, type, gen) {
      switch (type) {
        case 1:
          item.TreatAmount = parseFloat(item.Amount * item.number).toFixed(2);
          break;
        case 2:
          item.TreatAmount = (
            parseFloat(item.Amount) * parseFloat(item.number)
          ).toFixed(2);
          gen.TreatNumber = parseFloat(item.number * item.onceTreatTimes);
          break;
        case 3:
          item.TreatAmount = (
            parseFloat(item.Amount) * parseFloat(item.number)
          ).toFixed(2);
          break;
      }
    },
    //提交网络请求
    RefundTreatBillApply() {
      var that = this;
      var Product = [];
      var Project = [];
      var SavingCard = [];
      var GeneralCard = [];
      var TimeCard = [];
      var PackageCard = [];
      that.TreatBillList.forEach((BillList) => {
        BillList.project.forEach((pro) => {
          var RefundTreatHandler = [];
          pro.handleTypeList.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              RefundTreatHandler.push({
                TreatHandlerID: hand.ID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Discount,
              });
            });
          });
          Project.push({
            TreatBillID: pro.TreatBillID,
            TreatBillProjectID: pro.TreatBillProjectID,
            ProjectAccountID: pro.ProjectAccountID,
            ProjectID: pro.ProjectID,
            Quantity: pro.number,
            Amount: pro.Amount,
            TotalAmount: pro.TreatAmount,
            RefundTreatHandler: RefundTreatHandler,
          });
        });
        BillList.product.forEach((pro) => {
          var RefundTreatHandler = [];
          pro.handleTypeList.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              RefundTreatHandler.push({
                TreatHandlerID: hand.ID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Discount,
              });
            });
          });
          Product.push({
            TreatBillID: pro.TreatBillID,
            TreatBillProductID: pro.TreatBillProductID,
            ProductAccountID: pro.ProductAccountID,
            ProductID: pro.ProductID,
            Quantity: pro.number,
            Amount: pro.Amount,
            TotalAmount: pro.TreatAmount,
            RefundTreatHandler: RefundTreatHandler,
          });
        });
        BillList.generalCard.forEach((gen) => {
          var Project = [];
          gen.project.forEach((pro) => {
            var RefundTreatHandler = [];
            pro.handleTypeList.forEach((hand) => {
              hand.Employee.forEach((emp) => {
                RefundTreatHandler.push({
                  TreatHandlerID: hand.ID,
                  EmployeeID: emp.EmployeeID,
                  Scale: emp.Discount,
                });
              });
            });
            Project.push({
              TreatBillGeneralCardID: pro.TreatBillGeneralCardID,
              ProjectID: pro.id,
              Quantity: pro.number,
              CardTreatTimes: pro.number * pro.onceTreatTimes,
              Amount: pro.Amount,
              TotalAmount: pro.TreatAmount,
              RefundTreatHandler: RefundTreatHandler,
            });
          });
          GeneralCard.push({
            TreatBillID: gen.TreatBillID,
            GeneralCardAccountID: gen.GeneralCardAccountID,
            GeneralCardID: gen.GeneralCardID,
            Project: Project,
          });
        });

        BillList.timeCard.forEach((time) => {
          var Project = [];
          time.project.forEach((pro) => {
            var RefundTreatHandler = [];
            pro.handleTypeList.forEach((hand) => {
              hand.Employee.forEach((emp) => {
                RefundTreatHandler.push({
                  TreatHandlerID: hand.ID,
                  EmployeeID: emp.EmployeeID,
                  Scale: emp.Discount,
                });
              });
            });
            Project.push({
              TreatBillTimeCardID: pro.TreatBillTimeCardID,
              ProjectID: pro.id,
              Quantity: pro.number,
              Amount: pro.Amount,
              TotalAmount: pro.TreatAmount,
              RefundTreatHandler: RefundTreatHandler,
            });
          });
          TimeCard.push({
            TreatBillID: time.TreatBillID,
            TimeCardAccountID: time.TimeCardAccountID,
            TimeCardID: time.TimeCardID,
            Project: Project,
          });
        });

        BillList.savingCard.forEach((save) => {
          var Project = [];
          save.project.forEach((pro) => {
            var RefundTreatHandler = [];
            pro.handleTypeList.forEach((hand) => {
              hand.Employee.forEach((emp) => {
                RefundTreatHandler.push({
                  TreatHandlerID: hand.ID,
                  EmployeeID: emp.EmployeeID,
                  Scale: emp.Discount,
                });
              });
            });
            Project.push({
              TreatBillSavingCardID: pro.TreatBillSavingCardID,
              ProjectID: pro.id,
              Quantity: pro.number,
              TotalAmount: pro.TreatAmount,
              RefundTreatHandler: RefundTreatHandler,
            });
          });
          SavingCard.push({
            TreatBillID: save.TreatBillID,
            SavingCardAccountID: save.SavingCardAccountID,
            SavingCardID: save.SavingCardID,
            Project: Project,
          });
        });

        BillList.packageCard.forEach((pack) => {
          var Project = [];
          var Product = [];
          var GeneralCard = [];
          var TimeCard = [];
          var SavingCard = [];
          pack.Project.forEach((pro) => {
            var RefundTreatHandler = [];
            pro.handleTypeList.forEach((hand) => {
              hand.Employee.forEach((emp) => {
                RefundTreatHandler.push({
                  TreatHandlerID: hand.ID,
                  EmployeeID: emp.EmployeeID,
                  Scale: emp.Discount,
                });
              });
            });
            Project.push({
              TreatBillProjectID: pro.TreatBillProjectID,
              ProjectAccountID: pro.ProjectAccountID,
              ProjectID: pro.ProjectID,
              Quantity: pro.number,
              Amount: pro.Amount,
              TotalAmount: pro.TreatAmount,
              RefundTreatHandler: RefundTreatHandler,
            });
          });

          pack.Product.forEach((pro) => {
            var RefundTreatHandler = [];
            pro.handleTypeList.forEach((hand) => {
              hand.Employee.forEach((emp) => {
                RefundTreatHandler.push({
                  TreatHandlerID: hand.ID,
                  EmployeeID: emp.EmployeeID,
                  Scale: emp.Discount,
                });
              });
            });
            Product.push({
              TreatBillProductID: pro.TreatBillProductID,
              ProductAccountID: pro.ProductAccountID,
              ProductID: pro.ProductID,
              Quantity: pro.number,
              Amount: pro.Amount,
              TotalAmount: pro.TreatAmount,
              RefundTreatHandler: RefundTreatHandler,
            });
          });

          pack.GeneralCard.forEach((gen) => {
            var Project = [];
            gen.project.forEach((pro) => {
              var RefundTreatHandler = [];
              pro.handleTypeList.forEach((hand) => {
                hand.Employee.forEach((emp) => {
                  RefundTreatHandler.push({
                    TreatHandlerID: hand.ID,
                    EmployeeID: emp.EmployeeID,
                    Scale: emp.Discount,
                  });
                });
              });
              Project.push({
                TreatBillGeneralCardID: pro.TreatBillGeneralCardID,
                ProjectID: pro.ProjectID,
                Quantity: pro.number,
                CardTreatTimes: pro.number * pro.onceTreatTimes,
                Amount: pro.Amount,
                TotalAmount: pro.TreatAmount,
                RefundTreatHandler: RefundTreatHandler,
              });
            });
            GeneralCard.push({
              GeneralCardAccountID: gen.GeneralCardAccountID,
              GeneralCardID: gen.GeneralCardID,
              Project: Project,
            });
          });

          pack.TimeCard.forEach((time) => {
            var Project = [];
            time.project.forEach((pro) => {
              var RefundTreatHandler = [];
              pro.handleTypeList.forEach((hand) => {
                hand.Employee.forEach((emp) => {
                  RefundTreatHandler.push({
                    TreatHandlerID: hand.ID,
                    EmployeeID: emp.EmployeeID,
                    Scale: emp.Discount,
                  });
                });
              });
              Project.push({
                TreatBillTimeCardID: pro.TreatBillTimeCardID,
                ProjectID: pro.ProjectID,
                Quantity: pro.number,
                Amount: pro.Amount,
                TotalAmount: pro.TreatAmount,
                RefundTreatHandler: RefundTreatHandler,
              });
            });
            TimeCard.push({
              TimeCardAccountID: time.TimeCardAccountID,
              TimeCardID: time.TimeCardID,
              Project: Project,
            });
          });

          pack.SavingCard.forEach((save) => {
            var Project = [];
            save.project.forEach((pro) => {
              var RefundTreatHandler = [];
              pro.handleTypeList.forEach((hand) => {
                hand.Employee.forEach((emp) => {
                  RefundTreatHandler.push({
                    TreatHandlerID: hand.ID,
                    EmployeeID: emp.EmployeeID,
                    Scale: emp.Discount,
                  });
                });
              });
              Project.push({
                TreatBillSavingCardID: pro.TreatBillSavingCardID,
                ProjectID: pro.ProjectID,
                Quantity: pro.number,
                TotalAmount: pro.TreatAmount,
                RefundTreatHandler: RefundTreatHandler,
                TotalAmount: pro.TotalAmount,
              });
            });
            SavingCard.push({
              SavingCardAccountID: save.SavingCardAccountID,
              SavingCardID: save.SavingCardID,
              Project: Project,
            });
          });
          PackageCard.push({
            TreatBillID: pack.TreatBillID,
            PackageCardAccountID: pack.PackageCardAccountID,
            PackageCardID: pack.PackageCardID,
            Product: Product,
            Project: Project,
            SavingCard: SavingCard,
            GeneralCard: GeneralCard,
            TimeCard: TimeCard,
          });
        });
      });
      that.submitLoading = true;
      var params = {
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Amount: that.TreatAmount,
        Remark: that.Remake,
        Product: Product,
        Project: Project,
        SavingCard: SavingCard,
        GeneralCard: GeneralCard,
        TimeCard: TimeCard,
        PackageCard: PackageCard,
      };
      APIR.RefundTreatBillApply(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "提交申请成功",
              duration: 2000,
            });
            (that.Remake = ""), (that.TreatBillList = []);
            // that.TreatAmount = 0
            that.withdrawDialog = false;
            that.getRefundBillList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.submitLoading = false;
        });
    },
    //提交
    confrimWithdrawApplyClick() {
      var that = this;
      that.RefundTreatBillApply();
    },
  },
  mounted() {
    var that = this;
    that.employeeData();
    that.CustomerSourceData();
    that.CustomerLevelData();

    that.projectHandlerData();
    that.productHandlerData();
    that.savingCardHandlerData();
    // that.saleCustomerData();
  },
};
</script>

<style lang="scss">
.RefundTreatBillCreate {
  height: 100%;
  background-color: #ffffff;
  .itemWidth {
    width: 50%;
  }
  .refundTreatBill_create {
    height: calc(100% - 140px);
    .project_left {
      height: 100%;
      font-size: 13px;
      color: #666666;
      .el-scrollbar_height {
        height: 100%;
        .el-scrollbar__wrap {
          overflow-x: hidden;
          margin-bottom: 0 !important;
          .el-card__header {
            padding: 10px 20px;
            background-color: #f5f7fa;
          }
          .goods-item {
            line-height: 36px;
            font-size: 12px;
            margin-left: 20px;
            margin-right: 5px;
            min-height: 36px;
          }
          .goods-lable {
            color: #606266;
          }
        }
      }
    }

    .project_right {
      border-left: 1px solid #eeeeee;
      height: calc(100% + 70px);
      .el-main {
        padding: 0;
        .el-scrollbar_height {
          .el-card__header {
            padding: 10px 20px;
            background-color: #f5f7fa;
          }
          .goods-item {
            line-height: 36px;
            font-size: 12px;
            margin-left: 20px;
            margin-right: 5px;
          }
          .goods-lable {
            color: #606266;
          }
        }
      }
      .el-footer {
        height: initial !important;
        padding: 10px;
      }
    }
  }

  .order_create {
    padding: 0;
    height: 100%;
    .project_content {
      height: 100%;
      .el-input-group__prepend {
        width: 50px !important;
      }
    }
  }

  .consume_position_absolute {
    position: absolute;
    top: 63px;
    width: 90%;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    z-index: 100;
    height: 300px;
    font-size: 14px;
    color: #000;
    .customer_grade {
      font-size: 10px;
      margin-left: 15px;
      background-color: #f7e392;
      border-radius: 20px;
      color: #666;
      padding: 2px 5px;
    }
  }
  .backs_color {
    background-color: #ecf8ff;
  }
  .customer-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .info {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .info {
        color: #ddd;
      }
    }
  }
  .col_border {
    border-right: 1px solid #eee;
    padding: 5px 10px;
    color: #666;
    font-size: 13px;
  }
  .RefundTreatBillCreateHandler {
    .el-form-item__label {
      font-size: 13px !important;
      line-height: 18px;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 20px;
      // color: #c0c4cc;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .cardStyle_none {
    border: none;
    border-radius: 2px;
    border-bottom: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
  }
}

.customer-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .info {
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .info {
      color: #ddd;
    }
  }
}
</style>